.hero-section {
  position: relative;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white; 
  /* background-image: url('../../../assets/images/Ab.jpg');  */
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
  background-color: black; 
  z-index: 1;
}

.hero-content {
  z-index: 2;
  max-width: 80%;
  padding: 20px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.6);
}

.company-name {
  font-size: 3.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  animation: fadeIn 1s ease-in-out;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.tagline {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  animation: fadeIn 1.5s ease-in-out;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
}

.explore-button {
padding: 16px 40px;
font-size: 1.4rem;
font-family: 'Poppins', sans-serif;
font-weight: 700;
letter-spacing: 1.2px;
border: none;
border-radius: 50px;
cursor: pointer;
background: linear-gradient(45deg, #e63946, #ff6b6b);
color: white;
text-transform: uppercase;
transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease, color 0.3s ease;
animation: fadeIn 2s ease-in-out;
box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
}

.explore-button:hover {
transform: translateY(-5px);
box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.3);
background: linear-gradient(45deg, #ff6b6b, #e63946);
color: #ffffff;
}

.explore-button:active {
transform: translateY(2px);
box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
background: linear-gradient(45deg, #d62828, #e63946);
color: #ffffff;
}


@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

/* Responsive Design */
@media (max-width: 768px) {
    .company-name {
      font-size: 2.5rem;
    }
    .tagline {
      font-size: 1.25rem;
    }
    .explore-button {
      font-size: 1rem;
      padding: 12px 28px; /* Adjust padding for smaller screens */
    }
}

@media (max-width: 576px) {
    .company-name {
      font-size: 2rem;
    }
    .tagline {
      font-size: 1rem;
    }
    .explore-button {
      font-size: 0.9rem;
      padding: 10px 24px; /* Further adjustment for smallest screens */
    }
}