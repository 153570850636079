/* Gallery Card Label */
.gallery-card-label {
    font-size: 15px;
    font-weight: 600;
    margin-top: 12px;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

/* Gallery Container */
.gallery-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three cards per row */
    gap: 20px;
    justify-content: center;
    margin: 40px auto;
    max-width: 1200px;
    padding: 20px;
}

/* Individual Gallery Cards */
.gallery-card {
    background: #1c1c1e;
    border-radius: 15px;
    overflow: hidden;
    margin-bottom: 24px;
    width: 100%;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    text-align: center;
}

/* Hover Effect for Cards */
.gallery-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 20px rgba(30, 144, 255, 0.5);
}

/* Card Images */
.gallery-card img {
    width: 100%;
    height: 220px;
    object-fit: cover;
    border-radius: 15px 15px 0 0;
    transition: transform 0.3s ease;
}

/* Hover Effect for Images */
.gallery-card:hover img {
    transform: scale(1.1);
}

/* Card Inner Content */
.gallery-card-inner {
    padding: 16px;
    text-align: center;
}

/* Gallery Card Label Heading */
.gallery-card-label h3 {
    font-size: 16px; /* Adjusted for clarity */
    font-weight: bold;
    color: #ffffff;
    margin-top: 12px;
    background-color: rgba(0, 0, 0, 0.8); /* Enhanced readability */
    padding: 10px;
    border-radius: 5px;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.6);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

/* Responsive Design for Gallery Cards */
@media (max-width: 768px) {
    .gallery-container {
        grid-template-columns: repeat(2, 1fr); /* Two cards per row */
    }
}

@media (max-width: 576px) {
    .gallery-container {
        grid-template-columns: 1fr; /* One card per row */
    }
}

/* Intro Title Section */
.introtitle {
    background-color: #000000;
    text-align: center;
    padding: 40px 0;
}

/* Gradient Styling for Intro Title */
.introtitle_ {
    background: linear-gradient(to right, #7fffd4, #1e90ff, #9932cc);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 3.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

/* Below Title Image Styling */
.below-title-image {
    margin-top: 20px;
    width: 100%;
    max-width: 600px;
    border-radius: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

/* Experience Message */
.expmessage {
    font-size: 24px;
    font-style: italic;
    color: #ddd;
    margin-bottom: 0;
}

/* Iframe Modal */
.iframe-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Iframe Content */
.iframe-content {
    position: relative;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 20px;
    border-radius: 15px;
    width: 80%;
    max-width: 800px;
    max-height: 80vh;
    overflow-y: auto;
    color: #ffffff;
    z-index: 1010;
    box-shadow: 0 8px 30px rgba(30, 144, 255, 0.5);
}

/* Close Button */
.close-button {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 30px;
    color: #ffffff;
    background: none;
    border: none;
    cursor: pointer;
}

.close-button:hover {
    color: red;
}

/* Lecture Rooms List */
.lecture-rooms-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    padding: 20px 0;
}

/* Individual Lecture Room Item */
.lecture-room-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #ffffff;
    transition: transform 0.3s ease;
}

.lecture-room-item:hover {
    transform: translateY(-5px);
}

/* Lecture Room Images */
.lecture-room-item img {
    width: 100%;
    height: 120px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 10px;
}

/* Lecture Room Text */
.lecture-room-item span {
    text-align: center;
    font-size: 14px;
}

/* Bordered Container Styling */
.bordered-container {
   
    border-radius: 15px;
    padding: 20px;
    margin: 40px auto;
    max-width: 1200px;
}

/* Main Content Area */
.main-content {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 20px;
    /* background-color: #121212; */
    border-radius: 10px;
    /* box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3); */
    width: 98%;
    margin: 0 auto;
    min-height: fit-content;
    align-items: flex-start;
}

/* Left Column */
.left-column {
    flex: 0 0 450px;
    color: #e0e0e0;
    font-size: 17px;
    line-height: 1.6;
    text-align: justify;
    padding: 0 30px;
    /* background-color: rgba(0, 0, 0, 0.7); */
    /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4); */
    height: fit-content;
    margin-right: 20px;
}

/* Title Styling */
.title-prefix {
    color: #ffffff;
    font-weight: 500;
}

.title-zapaura {
    background: linear-gradient(120deg, #4facfe 0%, #00f2fe 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-weight: bold;
}

.left-column h2 {
    font-size: 2.2rem;
    margin-top: 0;
    margin-bottom: 20px;
    line-height: 1.2;
    letter-spacing: 0.5px;
}

/* Keyword Highlights with unique colors */
.explore-text {
    color: #4facfe; /* Bright blue */
    font-weight: 600;
    padding: 2px 8px;
    border-radius: 4px;
    background: rgba(79, 172, 254, 0.1);
}

.connect-text {
    color: #00f2fe; /* Cyan */
    font-weight: 600;
    padding: 2px 8px;
    border-radius: 4px;
    background: rgba(0, 242, 254, 0.1);
}

.create-text {
    color: #19e3b1; /* Mint green */
    font-weight: 600;
    padding: 2px 8px;
    border-radius: 4px;
    background: rgba(25, 227, 177, 0.1);
}

/* Right Column */
.right-column {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 0;
    align-content: start;
    min-width: 0;
}

/* Gallery Card */
.gallery-card {
    width: 100%;
    background: #1c1c1e;
    border-radius: 15px;
    overflow: hidden;
    margin-bottom: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    min-width: 220px;
}

/* Card Images */
.gallery-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.gallery-card-inner {
    padding: 15px;
}

.gallery-card-label h3 {
    font-size: 16px;
    margin-top: 10px;
    padding: 10px;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 8px;
}

/* Card Hover Effect */
.gallery-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 25px rgba(79, 172, 254, 0.2);
}

/* Responsive Design */
@media (max-width: 1400px) {
    .left-column {
        flex: 0 0 400px;
    }
    .right-column {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 1024px) {
    .main-content {
        flex-direction: column;
    }
    .left-column {
        width: 100%;
        margin-bottom: 20px;
        margin-right: 0;
    }
    .right-column {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .right-column {
        grid-template-columns: 1fr;
    }
    .gallery-card {
        min-width: unset;
    }
}