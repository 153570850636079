.navbar-nav .nav-item {
    margin-left: 15px;
    position: relative;
  }
  
  .navbar-nav .nav-link {
    color: #fff;
    padding: 10px 15px;
    transition: all 0.3s ease;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
  
  .navbar-nav .nav-link:hover {
    color: #f50; /* Matches the button color */
    background-color: rgba(255, 255, 255, 0.1); /* Adds a subtle background on hover */
    border-radius: 5px;
  }
  
  .navbar-nav .nav-link.active {
    color: #f50; /* Highlights the active link */
    border-bottom: 2px solid #f50; /* Adds an underline to the active link */
  }
  
  /* Scene Editor Button */
.btn-orange {
  background-color: #f50; /* Adjust the color to match your brand */
  border: none;
  color: #fff;
  font-weight: bold;
  padding: 10px 20px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btn-orange:hover {
  background-color: #f60; /* Slightly lighter or darker for hover effect */
}

/* Keep the button background when active */
.btn-orange:active, 
.btn-orange:focus, 
.btn-orange.active {
  background-color: #f50; /* Keeps the original background */
  color: #fff; /* Keeps the text color */
  outline: none; /* Removes default focus outline */
  box-shadow: none; /* Removes any potential focus shadow */
}

.btn-orange:visited {
  background-color: #f50; /* Ensures visited links keep the button background */
}

/* Active state when the Scene-Editor link is clicked */
.nav-item .btn-orange.active {
  background-color: #f50; /* Keeps the background color when it's active */
  border: none; /* Remove any borders that might appear */
}

/* Mobile specific adjustments */
@media (max-width: 991px) {
  .navbar-nav .nav-item {
    margin-left: 0;
    margin-bottom: 10px; /* Space between items in mobile view */
  }

  .navbar-nav .nav-link, .btn-orange {
    display: block;
    text-align: center; /* Center the text in mobile view */
    padding: 10px;
    font-size: 16px; /* Slightly larger text for easier clicking */
  }

  .navbar-collapse {
    text-align: center;
  }
}

.iframe-container {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80vw;
  height: 80vh;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  border-radius: 15px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.5);
}

.iframe-content {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 10px;
}

.close-iframe {
  position: absolute;
  top: 10px;
  right: 20px;
  background-color: #ff6b6b;
  color: white;
  border: none;
  font-size: 1.5rem;
  padding: 5px 10px;
  border-radius: 50%;
  cursor: pointer;
}

.close-iframe:hover {
  background-color: #e63946;
}