.footer {
    background: rgba(0, 0, 0, 0.9); /* Dark transparent background */
    color: white;
    text-align: center;
    padding: 40px 20px;
    font-family: 'Arial', sans-serif;
}

.footer-content h2 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
}

.footer-content p {
    font-size: 16px;
    color: #bbb;
    margin-bottom: 20px;
    line-height: 1.5;
}

.social-media {
    display: flex;
    justify-content: center;
    gap: 15px;
}

.social-icon {
    font-size: 24px;
    color: white;
    transition: transform 0.3s, color 0.3s;
}

.social-icon:hover {
    transform: scale(1.2);
    color: #ffcc00;
}
.email-link {
    color: #ffcc00; /* Gold color */
    text-decoration: none;
    font-weight: bold;
}

.email-link:hover {
    text-decoration: underline;
    color: #e6b800; /* Slightly darker gold for hover effect */
}
